.border-none[data-v-2e9ab996]:before {
  display: none;
}
.border-none[data-v-2e9ab996]:after {
  display: none;
}
.flex-fix[data-v-2e9ab996] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-2e9ab996]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-2e9ab996] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-2e9ab996] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-2e9ab996] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-2e9ab996] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-2e9ab996] {
  *zoom: 1;
}
.clear-fix[data-v-2e9ab996]:before,
.clear-fix[data-v-2e9ab996]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.amount-page[data-v-2e9ab996] {
  /*充值卡*/
  /*  商品卡*/
}
.amount-page .amount-box[data-v-2e9ab996] {
  margin-top: 0.2rem;
  margin-bottom: 0.3rem;
  background: #fff;
  padding: 0 0.24rem;
  font-size: 0.32rem;
  color: #333;
  width: 100%;
  height: 1rem;
}
.amount-page .amount-box .tip[data-v-2e9ab996] {
  font-size: 0.32rem;
  color: #999;
}
.amount-page .goods-list[data-v-2e9ab996] {
  background: #fff;
}
.amount-page .goods-list .goods-item[data-v-2e9ab996] {
  width: 100%;
  height: 2rem;
}
.amount-page .goods-list .goods-item .select-box[data-v-2e9ab996] {
  width: 11.2%;
  height: 100%;
  padding: 0 0.24rem;
}
.amount-page .goods-list .goods-item .select-box .select-item[data-v-2e9ab996] {
  width: 0.36rem;
  height: 0.36rem;
}
.amount-page .goods-list .goods-item .select-box .selected-item[data-v-2e9ab996] {
  width: 0.36rem;
  height: 0.36rem;
  border: 0.01rem solid #999;
  border-radius: 50%;
}
.amount-page .goods-list .goods-item .right[data-v-2e9ab996] {
  width: 88.8%;
  height: 100%;
  padding: 0.3rem 0;
}
.amount-page .goods-list .goods-item .right .goods-img[data-v-2e9ab996] {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.24rem;
}
.amount-page .goods-list .goods-item .right .goods-info[data-v-2e9ab996] {
  height: 100%;
  font-size: 0.32rem;
  color: #333;
}
.amount-page .goods-list .goods-item .right .goods-info .name[data-v-2e9ab996] {
  margin-bottom: 0.1rem;
}
.amount-page .goods-list .goods-item .right .goods-info .describe[data-v-2e9ab996] {
  font-size: 0.22rem;
  color: var(--main-color);
  padding: 0 0.12rem;
  min-width: 1.78rem;
  height: 0.36rem;
  line-height: 0.36rem;
  border-radius: 0.18rem;
  border: 0.02rem solid var(--main-color);
}
.amount-page .goods-list .goods-item .right .goods-info .price[data-v-2e9ab996] {
  font-size: 0.32rem;
  color: #FF4D4D;
  height: 0.4rem;
  line-height: 0.4rem;
  margin-top: 0.18rem;
}
.amount-page .goods-btn[data-v-2e9ab996] {
  width: 100%;
  height: 1.12rem;
  background: #fff;
  position: fixed;
  bottom: 0;
}
